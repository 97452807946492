<template>
  <div>
    <a-table
        :columns="tableList"
        :data-source="list"
        :pagination="pagination"
        :rowKey="(record, index) => index"
        @change="changePage"
    >
<!--   操作 -->
      <div slot="itemActionSlot" slot-scope="row">
      </div>
    </a-table>
  </div>
</template>
<script>
import {userQueryPriceResultListTableColumns} from "@/views/cmsPage/priceQueryAboutManage/uesrQueryPriceResult/_data"

export default {
  props: ['list', 'pagination'],
  components: {},
  data() {
    return {
      tableList: userQueryPriceResultListTableColumns,
    }
  },
  computed: {
  },
  methods: {
    /** 翻页 */
    changePage(page) {
      this.$emit("changePage", page);
    },
    handlePreviewImg(images, index) {
      const urls = images.map(el => {
        return {img_url: el}
      })
      this.$previewImg({
        list: urls,
        current: index,
        baseImgField: "img_url",
        showMute: false,
      });
    },
    handleConfirmSuccess() {
      this.$emit('success')
    }
  }
}
</script>
<style lang="scss" scoped>
.img-list {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
  img {
    width: 50px;
  }
}
.color-green {
  color: green;
}
.color-red {
  color: red;
}
</style>